<template lang="jade">
	.days(:style="{ '--dayNo': days.length }")
		.day(v-for="day in days") {{day.name.toUpperCase()}}
</template>

<script>
export default {
	props: {
		days: {
			type: Array,
			default: []
		}
	}
}
</script>

<style lang="stylus" scoped>
	.days
		display: grid
		grid-row-start: 2
		grid-column: 1/1
		grid-row-end: calc(var(--dayNo)+2)
		grid-gap: 2px
		@media only screen and (max-width: 500px)
			position: fixed
			height: 100%
			top: calc(10vh + 4rem + 2px)
			height: calc(90vh - 4rem - 2px)
			width: 7rem
			z-index: 1
		.day
			display: flex
			align-items: center
			grid-column: 1
			padding-left: 1rem
			background-color: #EFEFEF
</style>

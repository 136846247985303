<template lang="jade">
	.wrapper
		.filter-type
			label Timetable for:
			.select-dropdown
				select(v-model="filterType" @change="store.filter = filterType; getFirstValue()")
					option(v-for="(name, value) in store.filterMethods" :value="value") {{name}}
		.filter-value
			label {{store.filterMethods[filterType]}}:
			.select-dropdown
				select(v-model="store.filterValue")
					option(v-if="filterType == 'classes'" v-for="cls in orderedClasses" :value="cls.id") {{cls.name}}
					option(v-if="filterType == 'subjects'" v-for="subject in orderedSubjects" :value="subject.id") {{(isMobile)?subject.name:subject.short}}
					option(v-if="filterType == 'teachers'" v-for="teacher in orderedTeachers" :value="teacher.id") {{teacher.firstname}} {{teacher.lastname}}
					option(v-if="filterType == 'classrooms'" v-for="classroom in orderedClassrooms" :value="classroom.id") {{classroom.name}}
		// div.button-container
			a(href='/old/').old-link-button Old TT
</template>

<script>
	export default {
		props: {
			store:{
				type: Object,
				default: function(){ return {}}
			},
		},
		data() {
			return {
				filterType: this.store.filter,
			}
		},
		computed: {
			isMobile: function(){
				return screen.width>500;
			},
			orderedClasses: function(){
				return _.orderBy(this.store.classes, ['name']);
			},
			orderedSubjects: function(){
				return _.orderBy(this.store.subjects, ['name']);
			},
			orderedClassrooms: function(){
				return _.orderBy(this.store.classrooms, ['name']);
			},
			orderedTeachers: function(){
				return _.orderBy(this.store.teachers, ['firstname', 'lastname']);
			},
		},
		methods: {
			getFirstValue: function(){
				// add ordered in front and capitalize first letter of filter type
				var unorderedFilterName = this.filterType;
				var orderedFilterName = 'ordered'+unorderedFilterName.charAt(0).toUpperCase() + unorderedFilterName.substr(1);
				this.store.filterValue = this[orderedFilterName][0].id;
			}
		},
		updated: function(){
			if(this.store.filterValue === null){
				this.getFirstValue();
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.wrapper
		display: flex
		justify-content: center
		@media only screen and (max-width: 500px)
			justify-content: left
		padding: 1rem
		height: 10vh
		flex-flow: wrap row
		.filter-type, .filter-value
			display: flex
			// justify-content: center
			align-self: center
			text-align: center
			max-height: 3rem
			margin-bottom: 5px
			*
				display: flex
				justify-content: center
			& label
				flex-direction: column
				background-color: #e6e6e6
				border-radius: 2px 0px 0px 2px
				padding: 8px 24px 8px 24px
				border-right: 1px solid #fcfcfc
		.filter-value
			@media only screen and (min-width: 500px)
				margin-left: 1rem
		.select-dropdown
			position: relative
			background-color: #e6e6e6
			width: auto
			float: left
			max-width: 100%
			@media only screen and (max-width: 500px)
				max-width: 200px
			border-radius: 0px 2px 2px 0px

			select
				font-family: "helvetica neue", helvetica
				font-size: 1rem
				font-weight: 200
				max-width: 100%
				padding: 8px 24px 8px 10px
				border: none
				background-color: transparent
				-webkit-appearance: none
				-moz-appearance: none
				appearance: none
				&:active,
				&:focus
					outline: none
					box-shadow: none
			&:after
				content: " "
				position: absolute
				top: 50%
				margin-top: -2px
				right: 8px
				width: 0
				height: 0
				border-left: 5px solid transparent
				border-right: 5px solid transparent
				border-top: 5px solid #aaa
		.button-container
			display: flex
			.old-link-button
				font-size: 1.1rem
				align-self: center
				margin-left: 1rem
				margin-bottom: 5px
				background-color: #e6e6e6
				border-radius: 2px 0px 0px 2px
				padding: 8px 24px 8px 24px
				border-right: 1px solid #fcfcfc
				text-decoration: none
				color: inherit
				&:hover
					background-color: darken(#e6e6e6, 5)
</style>

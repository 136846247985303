<template lang="jade">
	.day(:style="{ '--day': dayindex }")
		Card(v-for='c in groupedFilteredCards' :card="c" :store="store")
</template>
<script>

	class PeriodGroup{
		constructor(p, pid){
			this.lessonids = Object.keys(p).sort();
			this.classroomids = Object.values(p)[0].classroomids;
			this.firstPeriod = pid;
			this.lastPeriod = pid;
		}

		addPeriod(p, pid){
			if(!_.isEqual(this.lessonids, Object.keys(p).sort()) ) { return false; }
			var flag = false;
			if(parseInt(pid) + 1 == this.firstPeriod){ this.firstPeriod = pid; flag=true; }
			if(pid - 1 == this.lastPeriod){ this.lastPeriod = pid; flag=true; }
			return flag;
		}
	}

	import Card from './card.vue';

	export default{
		props: ['day', 'dayindex', 'store'],
		components: {Card},
		computed: {
			groupedFilteredCards: function(){
				var periodGroups = [];
				for(var periodId in this.day){
					var period = this.day[periodId];
					var appended = false;
					for(var g in periodGroups){
						appended = appended || periodGroups[g].addPeriod(period, periodId);
					}
					if(!appended){
						periodGroups.push(new PeriodGroup(period, periodId));
					}
				}
				return periodGroups;
			}
		}
	}
</script>

<style lang="stylus">
	.day
		display: grid
		grid-row: calc( var(--day) + 1)
		grid-column: 1/last
		grid-template-columns: repeat(var(--periods), 1fr)
		.period
			display: flex
			flex-flow: wrap row
			grid-row: 1
			grid-column: var(--period)
			.card
				display: flex
				flex-direction: column
				background-color: var(--bg_color)
				color: var(--color)
				flex-grow: 1
				text-align: center
				justify-content: center
</style>

import Vue from 'vue';
import _ from 'lodash';
window._ = _;
// var parser = require('xml2json');
// import parser from 'xml2json';
import axios from 'axios';
var parseXML = require('xml2js').parseString;
import Timetable from './vue-components/timetable'
import TimetableFilter from './vue-components/timetable-filter'

Vue.config.productionTip = false

var store = {
	filter: 'classes',
	filterMethods: {teachers: 'Lecturer', subjects: 'Course', classes: 'Program', classrooms: 'Auditorium'},
	filterValue: null,
	classes: null,
	teachers: null,
	subjects: null,
	lessons: null,
	groups: null,
	days: null,
	periods: null,
	cards: null,
	classrooms: null
}

new Vue({
  el: '#app',
	template: `
		<div id="app">
			<TimetableFilter v-if="timetable" :store="store"/>

			<Timetable v-if="timetable" :store="store" />
		</div>`,
  components: { Timetable, TimetableFilter },
	data: {
		store: store,
		timetable: null,
	},
	methods: {
		getClasses: function(){
			var classesWithId = _.keyBy(this.timetable.classes[0].class, function(o){ return o['$']['id']; });
			return _.mapValues(classesWithId, function(o){ return o['$']});
		},
		getDays: function(){
			var all_days = this.timetable.daysdefs[0].daysdef;
			var daysWithId = _.keyBy(all_days, function(o){ return o['$']['id']; });
			var daysWithValues = _.mapValues(daysWithId, function(o){ return o['$']});
			var sortedDays = _.sortBy(daysWithValues, [function(o) { return -o.days; }]);
			return sortedDays;
		},
		getPeriods: function(){
			var periodsWithName = _.keyBy(this.timetable.periods[0].period, function(o){ return o['$']['period']; });
			return _.mapValues(periodsWithName, function(o){ return o['$']});
		},
		getGroups: function(){
			var groupsWithName = _.keyBy(this.timetable.groups[0].group, function(o){ return o['$']['id']; });
			return _.mapValues(groupsWithName, function(o){ return o['$']});
		},
		getClassrooms: function(){
			var classroomsWithName = _.keyBy(this.timetable.classrooms[0].classroom, function(o){ return o['$']['id']; });
			return _.mapValues(classroomsWithName, function(o){ return o['$']});
		},
		getSubjects: function(){
			var currentSubjects = _.map(this.timetable.lessons[0].lesson, function(o){ return o.$.subjectid; });
			var filteredSubjects = _.filter(this.timetable.subjects[0].subject, (o)=>{ return currentSubjects.indexOf(o['$']['id'])  != -1});
			var subjectsWithName = _.keyBy(filteredSubjects, function(o){ return o['$']['id']; });
			var subjects = _.mapValues(subjectsWithName, function(o){ return o['$']});
			return subjects;
		},
		getLessons: function(){
			var lessonWithId = _.keyBy(this.timetable.lessons[0].lesson, function(o){ return o['$']['id']; });
			var lessons = _.mapValues(lessonWithId, function(o){ return o['$']});

			var keys = Object.keys(lessons);
			for(var i = 0; i<keys.length; i++){
				// normally 70% yields nice results: think about it
				var hue = (i*50%255);
				var lightness = (70 - 3*(Math.round(i/100))%10);
				lessons[keys[i]]['bg_color']= 'hsla(' + hue + ', 100%, '+ lightness +'%, 1)';
				lessons[keys[i]]['color']= 'hsla( 0, 0%, '+ ((hue>200)?100:20) +'%, 1)';
			}
			return lessons;
		},
		getCards: function(){
			return _.mapValues(this.timetable.cards[0].card, (o)=>{ o.$.lesson = this.getLessons()[o.$.lessonid]; return o['$']});
		},
		getMinHour: function(){
			return _.reduce(this.getPeriods(), function(r, v, k){
				var d1 = new Date('01/01/2011 '+v.starttime['_value']);
				return (r>d1)?d1:r;
			}, new Date('01/01/2011 23:59'));
		},
		getMaxHour: function(){
			return _.reduce(this.getPeriods(), function(r, v, k){
				var d1 = new Date('01/01/2011 '+v.endtime['_value']);
				return (r<d1)?d1:r;
			}, new Date('01/01/2011 00:00'));
		},
		getTeachers: function(){
			var teachersWithId = _.keyBy(this.timetable.teachers[0].teacher, function(o){ return o['$']['id']; });
			return _.mapValues(teachersWithId, function(o){ return o['$']});
		},
	},
	beforeCreate: function(){
		axios.get('/timetable.xml').then((response)=>{
			parseXML(response.data, (err, res)=>{
				this.timetable = res.timetable;
			});
		});
	},
	updated: function(){
		if(this.timetable != null){
			this.store.teachers = this.getTeachers();
			this.store.classes = this.getClasses();
			this.store.subjects = this.getSubjects();
			this.store.days = this.getDays();
			this.store.cards = this.getCards();
			this.store.periods = this.getPeriods();
			this.store.lessons = this.getLessons();
			this.store.groups = this.getGroups();
			this.store.classrooms = this.getClassrooms();
		}
	}
})

<template lang="jade">
	.look(:style="{ '--dayNo': daysLength }")
		.period-col(v-for="(period, pid) in store.periods" :style="{ '--col': (parseInt(pid)) }")
		.day-row(v-for="(day, did) in store.days" v-if="did%2 == 0 && did > 0" :style="{ '--row': (parseInt(did)) }")

</template>

<script>
export default {
	props: ['store'],
	store:{
		type: Array,
		default: []
	},
	computed: {
		daysLength: function(){
			if(this.store != null && this.store.days != null) return this.store.days.length -2;
			return 0;
		}
	}
}
</script>

<style lang="stylus" scoped>
	.look
		display: grid
		grid-row-start: 2
		grid-row-end: calc(var(--dayNo)+2)
		grid-column-start: 2
		grid-column-end: last
		grid-template-columns: repeat(var(--periods), 1fr)
		grid-template-rows: repeat(var(--dayNo), 1fr)
		grid-gap: 2px
		.period-col
			border: 1px solid #E5E5E5
			border-width: 0 1px 0 1px
			grid-row-end: calc(var(--dayNo) + 1)
			grid-row-start: 1
			grid-column: var(--col)
			&::after
				content: ''
				border: 1px solid #fcfcfc
				float: right
				margin-right: -3px;
				height: 100%
		.day-row
			z-index: -1;
			grid-row-start: 1
			grid-row: var(--row)
			grid-column-end: last
			grid-column-start: 1
			background-color: #E5E5E5
</style>

<template lang="jade">
	.card(:style="{ '--start': card.firstPeriod, '--end': parseInt(card.lastPeriod)+1 }")
		.course(v-for="course in card.lessonids" v-if="l = getLesson(course, card.classroomids)"
			:style="{ '--bg_color': l.bg_color, '--color': l.color }")
			.teachers
				.teacher(v-for="t of l.teachers") {{t.firstname[0]}}. {{t.lastname}}
			.coursedata {{l.subject.short}}
			.groups
				.group(v-for="g of l.groups") {{g.name}}
			.classrooms
				.classrom(v-for="cr of l.classrooms") {{cr.short}}
</template>

<script>
export default {
	props: ['card','store'],
	methods: {
		getLesson: function(lessonid, classroomids){
			var lesson = this.store.lessons[lessonid];
			lesson['subject'] = this.store.subjects[lesson.subjectid];
			lesson['teachers'] = [];
			lesson['groups'] = [];
			lesson['classrooms'] = [];
			if(lesson.teacherids != ''){
				for(var t of lesson.teacherids.split(',')){
					lesson['teachers'].push(this.store.teachers[t]);
				}
			}
			for(var g of lesson.groupids.split(',')){
				lesson['groups'].push(this.store.groups[g]);
			}
			if(classroomids != ''){
				for(var cr of classroomids.split(',')){
					lesson['classrooms'].push(this.store.classrooms[cr]);
				}
			}
			return lesson;
		}

	}
}
</script>

<style lang="stylus" scoped>
	.card
		display: flex;
		flex-direction: column;
		grid-column-start: var(--start);
		grid-column-end: var(--end);
		.course
			position: relative;
			display: flex;
			flex-grow: 1;
			cursor: pointer;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			background-color: var(--bg_color);
			color: var(--color);
			&:hover{
				filter: brightness(80%);
			}
		.groups, .teachers, .classrooms
			font-size: 0.67rem;
			position: absolute;
		.groups
			display: none;
			bottom: 2px;
			left: 2px;
		.classrooms
			bottom: 2px;
			right: 2px;
		.teachers
			top: 2px;
			right: 2px;
</style>

<template lang="jade">
	.timetable(:style="{ '--periods': periodNo, '--dayNo': validDays.length }")
		PeriodsHeader(:periods="store.periods")
		DaysAside(:days="validDays")

		TimetableBorders(:store="store")

		.cards
			Day(v-for="(day, dayid) in filteredCards" :day="day"
				:dayIndex="validDays.findIndex(x=>x.days == dayid)" :store="store")
</template>

<script>
	import PeriodsHeader from './periods-header.vue';
	import DaysAside from './days-aside.vue';
	import TimetableBorders from './timetable-borders.vue';
	import Day from './day.vue';



	export default {
		components: {PeriodsHeader, DaysAside, TimetableBorders, Day},
		props:['store'],
		data(){
			return {
			}
		},
		computed: {
			periodNo: function(){
				if(this.store.periods != null){
					return Object.keys(this.store.periods).length;
				}
			},
			validDays: function(){
				return _.filter(this.store.days, function(o){return (o.days.split('1').length - 1) == 1})
			},
			filteredCards: function(){
				if(this.store.filter == 'classes'){
					var filteredDays = _.filter(this.store.cards, (o)=>{return o.lesson.classids.split(',').indexOf(this.store.filterValue) != -1});
				}else if(this.store.filter == 'subjects'){
					var filteredDays = _.filter(this.store.cards, (o)=>{return o.lesson.subjectid == this.store.filterValue});
				}
				else if(this.store.filter == 'teachers'){
					var filteredDays = _.filter(this.store.cards, (o)=>{return o.lesson.teacherids.split(',').indexOf(this.store.filterValue) != -1});
				}
				else if(this.store.filter == 'classrooms'){
					 var filteredDays = _.filter(this.store.cards, (o)=>{return o.classroomids.split(',').indexOf(this.store.filterValue) != -1});
				}
				var result = {};
				if(filteredDays == null) return result;

				for(var key in Object.keys(filteredDays)){ // object => days => periods
					var dayId = filteredDays[key].days;
					if(result[dayId] === undefined) result[dayId] = {}; // create new day if doesn't exist
					var periodId = filteredDays[key].period;
					if(result[dayId][periodId] === undefined) result[dayId][periodId] = {}; // create new period if it doesn't exist
					var lessonid = filteredDays[key]['lessonid'];
					result[dayId][periodId][lessonid] = filteredDays[key]; // append the card
				}
				return result;
			}
		}
	}
</script>
<style lang="stylus">
	.timetable
		overflow-x: scroll
		overflow-y: hidden
		// transform: translateZ(0)
		grid-template-columns: 10rem repeat(var(--periods), minmax(6rem, 1fr))
		@media only screen and (max-width: 500px)
			grid-template-columns: 7rem repeat(var(--periods), minmax(6rem, 1fr))
		grid-template-rows: 2rem repeat(var(--dayNo), 1fr)
		width: 100%
		// height: calc(90vh - 2.1rem)
		height: calc(90vh - 2rem)
		display: grid
		grid-gap: 2px
		.cards
			display: grid
			grid-row-start: 2
			grid-row-end: calc(var(--dayNo)+2)
			grid-column-start: 2
			grid-column-end: last
			grid-template-columns: repeat(var(--periods), 1fr)
			grid-template-rows: repeat(var(--dayNo), 1fr)
			grid-gap: 2px
</style>

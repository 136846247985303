<template lang="jade">
	.periods
		.period(v-for="(period, pid) in periods"
			:style="{ '--a': '\"'+period.endtime+'\"', '--b': '\"'+period.starttime+'\"' }") {{pid}}
</template>

<script>
export default {
	props: {
		periods: {
			type: Object,
			default: {}
		}
	}
}
</script>

<style lang="stylus">
	.periods
		display: grid
		grid-auto-flow: column
		grid-column: 2 / last
		grid-row: 1
		grid-auto-columns: 1fr
		.period
			display: flex
			align-items: center
			justify-content: space-around
			--a: ''
			--b: ''
			&::after
				content: var(--a)
				font-size: 0.5rem
				align-self: end
			&::before
				content: var(--b)
				font-size: 0.5rem
				align-self: end
</style>
